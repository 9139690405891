export abstract class HewSyncID<T = any> {
	constructor(public readonly value: T) {}

	public toJSON() {
		return this.value;
	}

	public toString(): string {
		return this.value as unknown as string; // TODO: Fix this
	}
}

export class AdminAccountID extends HewSyncID<string> {
	readonly type = "AdminAccount";
}
export class AdminGroupID extends HewSyncID<string> {
	readonly type = "AdminGroup";
}
export class AdminRoleID extends HewSyncID<string> {
	readonly type = "AdminRole";
}
export class AdminOrganizationID extends HewSyncID<string> {
	readonly type = "AdminOrganization";
}

export class ProjectTemplateID extends HewSyncID<string> {
	readonly type = "ProjectTemplate";
}
export class ProjectVersionID extends HewSyncID<string> {
	readonly type = "ProjectVersion";
}
export class ProjectInstanceID extends HewSyncID<string> {
	readonly type = "ProjectInstance";
}

export class SynapseSessionID extends HewSyncID<string> {
	readonly type = "SynapseSession";
}
export class SynapseBrokerID extends HewSyncID<string> {
	readonly type = "SynapseBroker";
}
export class SynapseNodeID extends HewSyncID<string> {
	readonly type = "SynapseNode";
}
export class SynapsePlanDefinitionID extends HewSyncID<string> {
	readonly type = "SynapsePlanDefinition";
}
export class SynapsePlanInstanceID extends HewSyncID<string> {
	readonly type = "SynapsePlanInstance";
}
export class SynapseTaskID extends HewSyncID<string> {
	readonly type = "SynapseTask";
}

export class UserAccountID extends HewSyncID<string> {
	readonly type = "UserAccount";
}
export class UserGroupID extends HewSyncID<string> {
	readonly type = "UserGroup";
}
export class UserRoleID extends HewSyncID<string> {
	readonly type = "UserRole";
}
export class UserOrganizationID extends HewSyncID<string> {
	readonly type = "UserOrganization";
}
export class UserAssetID extends HewSyncID<string> {
	readonly type = "UserAsset";
}
export class UserEntityID extends HewSyncID<string> {
	readonly type = "UserEntity";
}
export class UserEntityTokenID extends HewSyncID<string> {
	readonly type = "UserEntityToken";
}
export class UserInviteID extends HewSyncID<string> {
	readonly type = "UserInvite";
}

export class HewSyncPath extends HewSyncID<ReadonlyArray<string>> {
	readonly type = "HewSyncPath";

	public override toJSON() {
		return this.value;
	}

	public override toString() {
		return this.value.join("/");
	}
}
