import { BasicEvent } from "@h4x/common";
import type { Subscriber, Unsubscriber } from "svelte/store";
import { HewSync } from "./HewSync";
import type { HewSyncType, InternalHewSyncClass, InternalHewSyncType } from "./HewSyncType";
import type { HewSyncID } from "./IDs";
import type { SubscriptionEvent } from "./HewSyncSocket";

class HewSyncListData<T extends InternalHewSyncType> {
	public readonly onUpdate = new BasicEvent<() => void>();
	public readonly _items: T[] = [];

	public readonly loading: boolean = true;
	public readonly updating = false;
	public readonly errors: Error[] = [];

	private nextToken?: string | false;
	public readonly authorized: boolean = true;

	public readonly type: InternalHewSyncClass<T> & typeof HewSyncType;
	public constructor(type: typeof HewSyncType, private params?: { [K: string]: HewSyncID }) {
		this.type = type as any;

		let organization = (this.params?.["organization"] as HewSyncID)?.value;

		let requiredPermission = HewSync.PermissionsMap[this.type.name]!;

		if (organization && requiredPermission) {
			let permission = HewSync.permissionManager.check(requiredPermission, organization);
			console.debug(
				"[HewSyncListData] Permission check:",
				this.type.name,
				requiredPermission,
				permission,
				organization,
				HewSync.permissionManager
			);
			if (permission === false) {
				(this.authorized as any) = false;
				this.setLoaded();
				this.onUpdate.execute();
			}

			HewSync.permissionManager.onUpdate.addCallback(() => {
				let updatedPermission = HewSync.permissionManager.check(requiredPermission, organization);
				if (updatedPermission !== permission) {
					console.debug(
						"[HewSyncList] Permission recheck",
						this.type.name,
						requiredPermission,
						updatedPermission,
						organization,
						HewSync.permissionManager
					);
					if (updatedPermission === false) {
						(this.authorized as any) = false;
						(this.loading as any) = false;
						this._items.splice(0, this._items.length);
						this.nextToken = false;
						this.fetchMorePromise = undefined;
						this.onUpdate.execute();
					} else {
						(this.authorized as any) = true;
						(this.loading as any) = true;
						this._items.splice(0, this._items.length);
						this.nextToken = undefined;
						this.fetchMorePromise = undefined;
						this.fetchingAllPromise = undefined;
						this.fetchAll();
						this.onUpdate.execute();
					}
					permission = updatedPermission;
				}
			});
		}
	}

	private initializeSubscription() {
		// TODO: HewSync
		// eslint-disable-next-line @typescript-eslint/unbound-method
		this.type.onSubscriptionEvent.addCallback(this.handleSubscriptionEvent, this);
	}

	// private handleSubscriptionEvent(data: T & { removed?: boolean }) {
	private handleSubscriptionEvent(data: SubscriptionEvent) {
		console.debug("[List::handleSubscriptionEvent]", data);

		let { removals, additions } = HewSyncList.subscriptionToActions(this.type, data);

		console.debug("[List::handleSubscriptionEvent] Removals", removals);

		for (let key of removals) {
			let index = this._items.findIndex((x) => this.type.getKey(x) === key);
			if (index !== -1) {
				this._items.splice(index, 1);
			}
		}

		for (let data of additions) {
			let key = this.type.getKey(data);
			console.debug("[HewSyncList::handleSubscriptionEvent] Adding", key, data);
			let index = this._items.findIndex((x) => this.type.getKey(x) === key);
			if (index === -1) {
				let item = this.type.cache.get(key);
				if (item === undefined) {
					item = new this.type(data);
					(item as unknown as { setLoaded: () => void }).setLoaded();
					this.type.cache.set(key, item);
				}
				this._items.push(item);
			}
		}
		this.onUpdate.execute();

		/*let key = this.type.getKey(data);
		let found = false;

		for (const item of this._items) {
			let key2 = this.type.getKey(item);

			if (key2 === key) {
				if (data.removed) {
					console.debug("[HewSyncList::handleSubscriptionEvent] Removing", item);
					this._items.splice(this._items.indexOf(item), 1);
				}

				found = true;
				break;
			}
		}

		if (!found) {
			let item = this.type.cache.get(key);

			if (item === undefined) {
				// item = this.type.from(data);
				item = new this.type(this.type.parse(data));
				(item as unknown as { setLoaded: () => void }).setLoaded();
				this.type.cache.set(key, item);
			}

			if (item.loading === true) {
				(item as unknown as { apply: (data: any) => void }).apply(this.type.parse(data));
				(item as unknown as { setLoaded: () => void }).setLoaded();
			}

			this._items.push(item);
		}

		this.onUpdate.execute();*/
	}

	private loadInitialized = false;
	public async initialLoad() {
		if (this.loadInitialized === false) {
			this.loadInitialized = true;
			return this.fetchMore();
		}
		return this.nextToken !== false;
	}

	private subscriptionInitialized = false;
	private fetchMorePromise?: Promise<boolean>;
	public async fetchMore() {
		if (this.authorized === false) {
			return false;
		}
		if (this.fetchMorePromise !== undefined) {
			return await this.fetchMorePromise;
		}

		this.fetchMorePromise = this.fetchMoreInternal();
		await this.fetchMorePromise;
		return this.nextToken !== false;
	}

	private async fetchMoreInternal() {
		if (this.nextToken === false) {
			return false;
		}
		this.loadInitialized = true;
		if (this.subscriptionInitialized === false) {
			this.subscriptionInitialized = true;
			this.initializeSubscription();
		}
		/*let inputs = [];
		if (this.params !== undefined) {
			for (let [key, value] of Object.entries(this.params)) {
				inputs.push(new HewSync.QueryVariable(key, "ID", value));
			}
		}
		if (this.nextToken !== undefined) {
			inputs.push(new HewSync.QueryVariable("nextToken", "String", this.nextToken));
		}*/
		let inputs: { [K: string]: any } = {};
		if (this.params !== undefined) {
			for (let [key, value] of Object.entries(this.params)) {
				inputs[key] = value?.value;
			}
		}
		let query = HewSync.list<any>(this.type, inputs);
		let result;
		try {
			result = await query.execute();
		} catch (e) {
			if (e instanceof Error && e.message === "You are not authorized to make this call.") {
				console.warn(this.type.name, "Error fetching items", e);
				console.warn(e.message);
				(this.authorized as any) = false;
				this.nextToken = false;
				this.setLoaded();
				this.fetchMorePromise = undefined;
				this.onUpdate.execute();
				return false;
			} else {
				throw e;
			}
		}

		console.debug(this.type.name, "Fetched items", result);

		const items = result.items.map((x) => {
			let key = this.type.getKey(x);
			let cached = this.type.cache.get(key);
			if (cached !== undefined) {
				if (cached.loading === true) {
					(cached as unknown as { apply: (data: any) => void }).apply(x);
					(cached as unknown as { setLoaded: () => void }).setLoaded();
				}
				return cached;
			}
			// cached = this.type.from(x);
			cached = new this.type(x);
			(cached as unknown as { setLoaded: () => void }).setLoaded();
			this.type.cache.set(key, cached);
			return cached;
		});
		this._items.push(...items);
		this.nextToken = result.nextToken ?? false;
		this.setLoaded();
		this.fetchMorePromise = undefined;

		this.onUpdate.execute();

		return this.nextToken !== false;
	}

	private fetchingAllPromise?: Promise<void>;
	public async fetchAll() {
		if (this.fetchingAllPromise !== undefined) {
			return await this.fetchingAllPromise;
		}

		this.fetchingAllPromise = this.fetchAllInternal();
		await this.fetchingAllPromise;
	}

	private async fetchAllInternal() {
		while (await this.fetchMore()) {}
	}

	public get hasMore() {
		return this.nextToken !== false;
	}

	protected setLoaded() {
		(this.loading as any) = false;
	}

	public setUpdating(value: boolean) {
		(this.updating as any) = value;
		this.onUpdate.execute();
	}
}

export class HewSyncList<T extends InternalHewSyncType> {
	public readonly onUpdate = new BasicEvent<() => void>();

	private readonly store: HewSyncListData<T>;

	public get _items() {
		return this.store._items;
	}

	public get authorized() {
		return this.store.authorized;
	}

	public get loading() {
		return this.store.loading;
	}

	public get updating() {
		return this.store.updating;
	}

	public get errors() {
		return this.store.errors;
	}

	public get items() {
		return this._items.filter((x) => this.predicate?.(x) ?? true);
	}

	private static cache = new WeakMap<typeof HewSyncType, HewSyncListData<any>>();
	private static paramsCache = new WeakMap<typeof HewSyncType, Map<string, HewSyncListData<any>>>();

	public readonly type: typeof HewSyncType & InternalHewSyncClass<T>;
	private constructor(
		type: typeof HewSyncType,
		private predicate?: (data: T) => boolean,
		private params?: { [K: string]: HewSyncID }
	) {
		console.debug("[HewSyncList] Parameters:", typeof params);
		this.type = type as any;

		this.type.initSubscriptions();

		if (this.params !== undefined) {
			let key = Object.entries(this.params)
				.sort(([a], [b]) => a.localeCompare(b))
				.flat()
				.join(":");

			let cache = HewSyncList.paramsCache.get(type);
			if (cache === undefined) {
				cache = new Map();
				HewSyncList.paramsCache.set(type, cache);
			}

			let store = cache.get(key);
			if (store === undefined) {
				store = new HewSyncListData(type, this.params);
				cache.set(key, store);
			}

			this.store = store;
		} else {
			let store = HewSyncList.cache.get(type);
			if (store === undefined) {
				store = new HewSyncListData(type);
				HewSyncList.cache.set(type, store);
			}
			this.store = store;
		}

		this.store.onUpdate.addCallback(this.onUpdate.execute, this.onUpdate);
	}

	public filter(filter: (data: T) => boolean) {
		return new HewSyncList(this.type, filter, this.params);
	}

	public async initialLoad() {
		return this.store.initialLoad();
	}

	public async fetchMore() {
		return this.store.fetchMore();
	}

	public async fetchAll() {
		return this.store.fetchAll();
	}

	public get hasMore() {
		return this.store.hasMore;
	}

	public setUpdating(value: boolean) {
		this.store.setUpdating(value);
	}

	public subscribe(run: Subscriber<this>): Unsubscriber {
		let ref = this.onUpdate.addCallback(() => {
			run(this);
		});
		run(this);
		return () => {
			ref.remove();
		};
	}

	public static subscriptionToActions(type: InternalHewSyncClass<any>, subscription: SubscriptionEvent) {
		let removals = new Set<string>();
		let additions = new Set<any>();

		if (subscription.$event === "Created") {
			additions.add(subscription);
		} else if (subscription.$event === "Updated") {
			// nothing to do
		} else if (subscription.$event === "KeyUpdated") {
			removals.add(type.getKey(subscription.old));
			additions.add(subscription.updated);
		} else if (subscription.$event === "Removed") {
			removals.add(type.getKey(subscription as any));
		}

		return { removals, additions };
	}
}
